*{
    margin: 0;
    padding: 0;
}

.header{
    min-height: 80vh;
    width: 100%;
    background-image: linear-gradient(rgba(4,9,30,0.7),rgba(4,9,30,0.7)) , url(images/Quickery.png);
    background-position: center;
    background-size: cover;
    position: relative;
}

nav{
    display: flex;
    padding: 2% 6%;
    justify-content: space-between;
    align-items: center;
}

nav img {
    width: 150px;
}

.nav-links{
    flex: 1;
    text-align: right;
}

.nav-links ul li{
    list-style: none;
    display: inline-block;
    padding: 8px 12px;
    position: relative;
}

.nav-links ul li a {
    color: white;
    font-size: 13px;
    text-decoration: none;

}

.nav-links ul li::after{
    content: '';
    width: 0%;
    height: 2px;
    background: #f44336;
    display: block;
    margin: auto;
    transition: 0.5s;
}
.nav-links ul li:hover::after{
    width: 100%;
}

.text-box{
    width: 90%;
    color: white;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    text-align: center;
}

.text-box h1{
    font-size: 62px;
}

.text-box p{
    margin: 10px 0 40px;
    font-size: 14px;
    color: white;
}

.hero-btn{
    display: inline-block;
    text-decoration: none;
    color: #fff;
    border:1px solid #fff;
    padding: 12px 34px;
    font-size: 13px;
    background: transparent;
    position: relative;
    cursor: pointer;
}

.hero-btn:hover{
    border: 1px solid #f44336;
    background: #f44336;
    transition: 1s;
}

nav .fa{
    display: none;
}

/* Wave container styling for the header */
.wave-container {
    position:absolute;
    bottom: 0;
    width: 100%;
    overflow: hidden;
    line-height: 0;
}

.wave-container svg {
    position: relative;
    display: block;
    width: 100%;
    height: auto;
    margin-top: -10px; /* Adjusts overlap with the header */
}

/* Wave border effect */
.wave-container svg path {
    fill: #fff; /* This matches the background color of the next section */
}

@media(max-width: 700px){
    .text-box h1{
        font-size: 20px;
    }
    .nav-links ul li{
        display: block;
    }
    .nav-links{
        position: absolute;
        background: #f44336;
        height: 100vh;
        width: 200px;
        top: 0;
        right: -200px;
        text-align: left;
        z-index: 2;
        transition: 1s;
    }
    nav .fa{
        display: block;
        color: #fff;
        margin: 10px;
        font-size: 22px;
        cursor: pointer;
    }
    .nav-links ul {
        padding: 30px;
    }
}


/* course */

.Course{
    width: 80;
    margin: auto;
    text-align: center;
    padding-top: 30px;
}

h1{
    font-size: 36px;
    font-weight: 600;
}

p{
    color: #777;
    font-size: 14px;
    font-weight: 300;
    line-height: 22px;
    padding: 10px;
}

.row{
    margin-top: 5%;
    display: flex;
    justify-content: space-between;
}
/* 
.course-col{
    flex-basis: 31%;
    background: #fff;
    border-radius: 50%;
    margin-bottom: 5%;
    padding:20px 12px;
    box-sizing: border-box;
    transition: 0.5s;
}

.course-col img{
    height: 300px;
    margin-left: 5px;
    margin-right: 30px;
    border-radius: 50%;
}

h3{
    text-align: center;
    font-weight: 600;
    margin: 10px 0;
}

.course-col:hover{
    box-shadow: 0 0 20px 0px rgba(0,0, 0,2);
} */

.course-col {
    /* flex-basis: 31%; */
    background: #fff;
    /* border-radius: 50%; */
    margin-bottom: 5%;
    padding: 20px 12px;
    box-sizing: border-box;
    transition: 0.5s;
    overflow: hidden; /* Make sure the content inside doesn't overflow the border */
}

/* Ensure image fits well and is centered inside the column */
.course-col img {
    height: 300px;
    margin-left: 5px;
    margin-right: 30px;
    border-radius: 50%;
    transition: transform 0.5s ease; /* Add smooth transition for zoom */
}

/* Zoom effect on hover */
.course-col:hover img {
    transform: scale(1.1); /* Zoom the image slightly */
}

/* .course-col:hover {
    box-shadow: 0 0 20px 0px rgba(0, 0, 0, 0.2);
} */

@media(max-width: 700px){
    .row{
        flex-direction: column;
    }
}

/* -------Campus-------- */

.campus {
    background-color: #f4f4f4;
    padding: 50px 0;
    text-align: center;
    margin: auto;
}

.campus-col{
    flex-basis: 32%;
    border-radius: 10px;
    margin-bottom: 30px;
    position: relative;
    overflow: hidden;
}

.campus-col img{
    width: 100%;
    display: block;
}

.layer{
    background: transparent;
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    transition: 0.5s;
}

.layer:hover{
background: rgba(226, 0, 0, 0.7);
}

.layer h3{
    width: 100%;
    font-weight: 500;
    color: #fff;
    font-size: 26px;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    position: absolute;
    opacity: 0;
    transition: 0.5s;
}

.layer:hover h3{
    bottom: 49%;
    opacity: 1;
}


/* ------facilities----- */

.facilities{
    width: 80%;
    margin: auto;
    text-align: center;
    padding-top: 100px;
}

.facilities-col{
    flex-basis: 31%;
    border-radius: 10px;
    margin-bottom: 5%;
    text-align: left;
}

.facilities-col img{
    width: 100%;
    border-radius: 10px;
}

.facilities-col p{
    padding: 0;
}
.facilities-col h3{
    margin-top: 16px;
    margin-bottom: 15px;
    text-align: left;
}


/* --------testimonials--------- */
.testimonials{
    width: 80%;
    margin: auto;
    padding-top: 100px;
    text-align: center;
}

.testimonials-col{
    flex-basis: 44%;
    border-radius: 10px;
    margin-bottom: 5%;
    text-align: center;
    background: #fff3f3;
    padding: 25px;
    cursor: pointer;
    display: flex;
}

.testimonials-col img{
    height: 40px;
    margin-left: 5px;
    margin-right: 30px;
    border-radius: 50%;
}
.testimonials-col p{
    padding: 0;
}
.testimonials-col h3{
    margin-top: 15px;
    text-align:left ;
}
.testimonials-col .fa{
    color: #f44336;
}
@media(max-width:700px){
    .testimonials-col img{
        margin-left: 0px;
        margin-right: 15px;
    }
}


/* ----footer------ */


.footer {
    position: absolute;
    /* bottom: 20%; */
    /* top: 50%; */
    left: 50%;
    transform: translate(-50%, -50%);
    max-width: 1280px;
    width: 100%;
    background: #10182F;
    border-radius: 6px;
  }
  .footer .footer-row {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 3.5rem;
    padding: 60px;
  }
  .footer-row .footer-col h4 {
    color: #fff;
    font-size: 1.2rem;
    font-weight: 400;
  }
  .footer-col .links {
    margin-top: 20px;
  }
  .footer-col .links li {
    list-style: none;
    margin-bottom: 10px;
  }
  .footer-col .links li a {
    text-decoration: none;
    color: #bfbfbf;
  }
  .footer-col .links li a:hover {
    color: #fff;
  }
  .footer-col p {
    margin: 20px 0;
    color: #bfbfbf;
    max-width: 300px;
  }
  .footer-col form {
    display: flex;
    gap: 5px;
  }
  .footer-col input {
    height: 40px;
    border-radius: 6px;
    background: none;
    width: 100%;
    outline: none;
    border: 1px solid #7489C6 ;
    caret-color: #fff;
    color: #fff;
    padding-left: 10px;
  }
  .footer-col input::placeholder {
    color: #ccc;
  }
   .footer-col form button {
    background: #fff;
    outline: none;
    border: none;
    padding: 10px 15px;
    border-radius: 6px;
    cursor: pointer;
    font-weight: 500;
    transition: 0.2s ease;
  }
  .footer-col form button:hover {
    background: #cecccc;
  }
  .footer-col .icons {
    display: flex;
    margin-top: 30px;
    gap: 30px;
    cursor: pointer;
  }
  .footer-col .icons i {
    color: #afb6c7;
  }
  .footer-col .icons i:hover  {
    color: #fff;
  }
  @media (max-width: 768px) {
    .footer {
      position: relative;
      bottom: 0;
      left: 0;
      transform: none;
      width: 100%;
      border-radius: 0;
    }
    .footer .footer-row {
      padding: 20px;
      gap: 1rem;
    }
    .footer-col form {
      display: block;
    }
    .footer-col form :where(input, button) {
      width: 100%;
    }
    .footer-col form button {
      margin: 10px 0 0 0;
    }
  }
